


























































































import {Component, Vue} from "vue-property-decorator";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipAltTipiResponseProvider} from "@/services/TakipAltTipiService";
import TakipAltTipAlanlarList from "@/components/tercihler/TakipAltTipAlanlarList.vue";
import {MuvekkilListResponseProvider} from "@/services/MuvekkilService";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import TahsilatDusmeSirasi from "@/components/tercihler/TahsilatDusmeSirasi.vue";
import {VekaletUcretleriTaslakListProvider} from "@/services/VekaletUcretleriTaslaklarService";

@Component({
    components: {
        TahsilatDusmeSirasi,
        Faizler,
        TakipAltTipAlanlarList

    },
})
export default class TakipAltTipler extends Vue {
    item: TakipAltTipiEntity | null = null
    muvekkilListesi = MuvekkilListResponseProvider().data;
    VekaletUcretiTaslakListesi = VekaletUcretleriTaslakListProvider().data;


    mounted() {
        window.setTimeout(() => {
            let index: number = parseInt(this.$route.params.id) - 1;
            this.item = TakipAltTipiResponseProvider().data[index];
            console.log("Takip Alt Tip:     ", this.item);
        }, 100);

    }
}

