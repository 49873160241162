












import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {HesapDonemi, HesapDonemiLabel} from "../../enum/HesapDonemi";

@Component({
    components: {EnumPicker}
})
export default class HesapDonemiPicker extends Mixins(SimpleInputMixin) {
    turler = HesapDonemi;
    turlerLabel = HesapDonemiLabel;
}
