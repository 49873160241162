












import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {HesapSekli, HesapSekliLabel} from "../../enum/HesapSekli";

@Component({
    components: {EnumPicker}
})
export default class HesapSekliPicker extends Mixins(SimpleInputMixin) {
    turler = HesapSekli;
    turlerLabel = HesapSekliLabel;
}
