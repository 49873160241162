






















































import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {TakipAltTipAyar} from "@/entity/TakipAltTipAyar";
import draggable from 'vuedraggable'
import {TakipAltTipAyarlarResponseProvider} from "@/services/TakipAltTipAyarlarService";
import HesapSekliPicker from "@/components/pickers/HesapSekliPicker.vue";
import HesapDonemiPicker from "@/components/pickers/HesapDonemiPicker.vue";


@Component({
    components: {HesapDonemiPicker, HesapSekliPicker, FormDialogButton,draggable}
})
export default class TakipAltTipAlanlarList extends Vue {
    @Prop()
    value!:Array<TakipAltTipAyar>;
    items = TakipAltTipAyarlarResponseProvider().data;
    drag!:false;
    Headers=[
        {
            text: "Adı",
            align: "start",
            sortable: false,
            value: "alanAdi"
        },

        {text: "Tercih Edilen Ad", value: "tercihEdilenAd"},
        {text: "Oranlanan Alan", value: "oranlananAlan"},
        {text: "Oran", value: "oran"},
        {text: "Sabit Tutar", value: "sabitTutar"},
        {text: "Takip Sonrası Faiz İşlesin Mi", value: "takipSonrasiFaizIslesinMi"},
    ]
}

/*
<draggable :list="value"  @start="drag=true" @end="drag=false">
            <div v-for="element in value" :key="element.id">{{element.alanAdi}}</div>
        </draggable>
 */
